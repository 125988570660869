import React from 'react'
import './about.css'
import ME from '../../assets/IMG6821 copy.JPG'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'
import {FiUsers} from 'react-icons/fi'


const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
      

      <div className="about_content">
        <div className="about_cards">
          <article className='about_card'>
            <FaAward className='about_icon'/>
            <h5>Experience</h5>
            <small>3+ Years Working</small>
          </article>

          <article className='about_card'>
            <VscFolderLibrary className='about_icon'/>
            <h5>Education</h5>
            <small>Master of Information Technology and Systems</small>
          </article>

          <article className='about_card'>
            <FiUsers className='about_icon'/>
            <h5>Language</h5>
            <small>English / Mandarin / French</small>
          </article>
          </div>

          <p>
            Proactive and innovative software developer
          </p>

          <a href="#contact" className='btn btn-primary'>Get In Touch</a>


        </div>
      </div>
    </section>
  )
}

export default About