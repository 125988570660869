import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/IMG6821.JPG'
import HeaderSocial from './HeaderSocials'


const Header = () => {
  return (
    <header>
      <div className="container header_container">
        <h5>Hello I'm</h5>
        <h1>Angela Tseng</h1>
        
        <h5 className="text-light">Systems Administrator / ICT Support</h5>
        <HeaderSocial /><CTA />
        

        {/* <div className="me">
          <img src={ME} alt="me" />
        </div> */}

        <a href="#contact" className='scroll_down'>Scroll Down</a>

      </div>
    </header>
  )
}

export default Header