import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolio1.png'
import IMG2 from '../../assets/portfolio2.png'
import IMG3 from '../../assets/portfolio3.png'
import IMG4 from '../../assets/portfolio4.jpg'
import IMG5 from '../../assets/portfolio5.jpg'
import IMG6 from '../../assets/portfolio6.jpg'



const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Global Weather Checking Website',
    github: 'https://github.com',
    demo: 'https://angelatseng.com'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Job Board App',
    github: 'https://github.com',
    demo: 'https://www.canva.com/design/DAFOpA0NloY/MhfLTAZeUCuqAr_3MIlUoA/watch?utm_content=DAFOpA0NloY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Christmas Lunch App',
    github: 'https://github.com',
    demo: 'https://www.canva.com/design/DAFOpPxgeZM/zDvctP0pDMt0rGotxdFBEQ/watch?utm_content=DAFOpPxgeZM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink'
  }
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                {/* <a href={github} className='btn' target='_blank'>Github</a>*/}
                <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio