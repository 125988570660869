import React from 'react'

import './testimonials.css'
import AVTR1 from '../../assets/avatar1.png'
import AVTR2 from '../../assets/avatar2.png'
import AVTR3 from '../../assets/avatar3.png'
import AVTR4 from '../../assets/avatar4.png'
import AVTR5 from '../../assets/avatar5.png'

//import Swiper core and required modules
import { Pagination, Navigation, Scrollbar, Ally } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
//import { Swiper, SwiperSlide } from 'swiper/react/swiper-react'

//Import Swiper Styles
import "swiper/swiper.min.css";








const data = [
  {
    avatar: AVTR5,
    name: 'VMware Data Center Virtualization',
    review: 'VMware / 2023'
  },
  {
    avatar: AVTR4,
    name: 'Azure Fundamentals',
    review: 'Microsoft / 2022'
  },
  {
    avatar: AVTR3,
    name: 'Power Platform Fundamentals',
    review: 'Microsoft / 2022'
  },
  {
    avatar: AVTR1,
    name: 'Dynamics 365 Fundamentals (ERP)',
    review: 'Microsoft / 2022'
  },
  {
    avatar: AVTR2,
    name: 'Dynamics 365 Fundamentals (CRM)',
    review: 'Microsoft / 2022'
  },
  
  


]


const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5></h5>
      <h2>Certificates</h2>

      <Swiper className="container testimonials_container"
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={40}
      slidesPerView={1}
      navigation={{ clickable: true }}
      pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, review}, index) => {
            return (
              <SwiperSlide key={index} className="testimonial">
              <div className="client_avatar">
                <img src={avatar}/>
              </div>
              <h5 className='client_name'>{name}</h5>
              <small className='client_review'>{review}</small>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials